import { ReactNode, MouseEventHandler } from 'react'
import Image from 'next/legacy/image'
import { m } from 'framer-motion'
import Text from 'components/text'
import s from './style.module.css'

type Handler = MouseEventHandler<HTMLDivElement>
interface QuotesProps {
	children: ReactNode
	onMouseOver: Handler
	onMouseOut: Handler
}

export default function Quotes({
	children,
	onMouseOver,
	onMouseOut,
}: QuotesProps) {
	return (
		<div
			className={s.quotesWrapper}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
		>
			<Image
				src={require('./accent.svg')}
				width={20}
				height={14}
				layout="intrinsic"
				alt="" // Decorative element
			/>
			<ul className={s.quotes}>{children}</ul>
		</div>
	)
}

export interface QuoteProps {
	text: string
	name: string
	jobTitle: string
	company: string
}

interface QuoteSlideProps extends QuoteProps {
	index: number
	activeIndex: number
}

const variants = {
	left: {
		opacity: 0,
		x: '-100%',
	},
	center: {
		opacity: 1,
		x: 0,
	},
	right: {
		opacity: 0,
		x: '100%',
	},
}

export function QuoteSlide({
	index,
	activeIndex,
	text,
	name,
	jobTitle,
	company,
}: QuoteSlideProps) {
	return (
		<m.li
			className={s.quoteSlide}
			variants={variants}
			initial={false}
			animate={
				// eslint-disable-next-line no-nested-ternary
				index === activeIndex
					? 'center'
					: activeIndex > index
					? 'left'
					: 'right'
			}
			transition={{
				ease: [0.32, 0.72, 0, 1],
				duration: 1.25,
			}}
			aria-hidden={activeIndex !== index}
		>
			<figure className={s.quoteFigure}>
				<blockquote className={s.blockquote}>
					<Text
						fontWeight="semi-bold"
						className={s.quoteText}
						marginTop="03"
						marginBottom={{ sm: '04', md: '03' }}
					>
						{text}
					</Text>
				</blockquote>
				<figcaption className={s.quoteCaption}>
					<Text variant="label" marginBottom="01">
						{name}
					</Text>
					<Text variant="label" color="neutral-500">
						{company} | {jobTitle}
					</Text>
				</figcaption>
			</figure>
		</m.li>
	)
}
