import { HDSPlusStandaloneLink } from '@hashicorp/react-mds/src/hds-plus/standalone-link'
import type { ComponentProps } from 'react'
import { IconArrowRight16 } from '@hashicorp/flight-icons/svg-react/arrow-right-16'

type StandaloneLinkProps = Omit<
	ComponentProps<typeof HDSPlusStandaloneLink>,
	'icon' | 'iconPosition'
>

export default function StandaloneLink(props: StandaloneLinkProps) {
	return (
		<HDSPlusStandaloneLink
			{...props}
			icon={
				<IconArrowRight16
					style={{ display: 'block' }}
					width="100%"
					height="100%"
				/>
			}
			iconPosition="trailing"
		/>
	)
}
