import { ReactNode } from 'react'
import classnames from 'classnames'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import type { QuoteProps } from '../quotes'
import s from './style.module.css'

export default function QuoteControls({ children }: { children: ReactNode }) {
	return <ul className={s.controls}>{children}</ul>
}

interface QuoteControlProps extends Omit<QuoteProps, 'text' | 'company'> {
	isActive?: boolean
	onClick: () => void
}

export function QuoteControl({
	isActive = false,
	name,
	jobTitle,
	onClick,
}: QuoteControlProps) {
	return (
		<li className={s.controlItem}>
			<button onClick={onClick} className={s.controlButton}>
				<span
					className={classnames(s.dot, {
						[s.isActive]: isActive,
					})}
				/>
				<VisuallyHidden>
					{`Testimonial from ${name} - ${jobTitle}`}
				</VisuallyHidden>
				{isActive && <VisuallyHidden>Current Testimonial slide</VisuallyHidden>}
			</button>
		</li>
	)
}
