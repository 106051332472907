import { useEffect, useRef } from 'react'

// This hook is essentially the same as  https://usehooks-typescript.com/react-hook/use-interval
// We do however reset the interval based on changes to the incoming callback function, see comment below

function useInterval(callback: () => void, delay: number | null) {
	const savedCallback = useRef(callback)

	// Remember the latest callback if it changes.
	useEffect(() => {
		savedCallback.current = callback
	}, [callback])

	// Set up the interval.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		if (delay === null) {
			return
		}

		const id = setInterval(() => savedCallback.current(), delay)

		return () => clearInterval(id)
	}, [delay, callback]) // Creating a dependency on callback here allows for the timer to be reset upon callback change
}

export default useInterval
