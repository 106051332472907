'use client'

import useCounter from 'lib/hooks/use-counter'
import Text from 'components/text'
import s from './style.module.css'

export interface StatItemProps {
	prefix?: string
	number: number
	suffix?: string
	subheading: string
	appearance?: 'dark' | 'light'
	className?: string
}

export default function StatItem({
	prefix,
	number,
	suffix,
	subheading,
	appearance = 'light',
	className,
}: StatItemProps) {
	const { ref, count } = useCounter({
		from: number > 10 ? 10 : 0,
		to: number,
	})

	return (
		<div className={className} ref={ref}>
			<Text
				as="h3"
				variant="display-1"
				marginBottom="01"
				className={s.heading}
				color={appearance === 'light' ? 'neutral-900' : 'neutral-0'}
			>
				{prefix && <span>{prefix}</span>}
				<span className={s.number}>{count}</span>
				{suffix && <span>{suffix}</span>}
			</Text>
			<Text
				as="h4"
				variant="display-5"
				color={appearance === 'light' ? 'neutral-600' : 'neutral-500'}
				className={s.subheading}
			>
				{subheading}
			</Text>
		</div>
	)
}
