'use client'

import { usePathname, Link } from 'lib/i18n/routing'
import { AnimatePresence, m } from 'framer-motion'
import classNames from 'classnames'
import FlightIcon from '@hashicorp/react-hds/src/components/flight-icon'
import { type ProductsSubnavProps } from './types'
import ReactSubnav from '@hashicorp/react-components/src/components/subnav'
import s from './style.module.css'

const ProductsSubnav = ({
	product,
	inView,
	titleLink,
	menuItems,
	ctaLinks,
	initialTheme = 'light',
}: ProductsSubnavProps) => {
	const path = usePathname()
	// The second CTA for all HCP products renders a cloud icon.
	// Since Nomad is not an HCP product, we don't render a cloud icon.
	const ctaLinksWithIcon = ctaLinks.map((link, index) => {
		const isFirst = index === 0
		const isNomad = product === 'nomad'

		return {
			...link,
			icon: isFirst || isNomad ? null : <FlightIcon name="cloud" stretched />,
		}
	})
	const animationProps = {
		initial: { opacity: 0 },
		animate: { opacity: 1 },
		exit: { opacity: 0 },
	}
	const subnavProps = {
		titleLink: titleLink,
		menuItems: menuItems,
		product,
		// Dato validations are such that we require a full URL for this field (versus just a path).
		// In order for the _isActiveUrl logic to work, we need to prepend the domain to currentPath.
		currentPath: `https://www.hashicorp.com${path}`,
		Link: Link,
	}
	return (
		<div className={s.navOuter}>
			<AnimatePresence>
				{inView ? (
					<m.div key={'initialSubnav'} className={s.nav} {...animationProps}>
						<ReactSubnav
							{...subnavProps}
							className={classNames([
								s.navInner,
								{ [s.dark]: initialTheme === 'dark' },
							])}
							ctaLinks={[]}
							theme={initialTheme}
						/>
					</m.div>
				) : (
					<m.div key={'stickySubnav'} className={s.nav} {...animationProps}>
						<ReactSubnav
							{...subnavProps}
							className={s.navInner}
							ctaLinks={ctaLinksWithIcon}
							theme={'light'}
						/>
					</m.div>
				)}
			</AnimatePresence>
		</div>
	)
}

export default ProductsSubnav
