'use client'

import React, { useState } from 'react'
import { VisuallyHidden } from '@radix-ui/react-visually-hidden'
import useRovingIndex from 'lib/hooks/use-roving-index'
import useInterval from 'lib/hooks/use-interval'
import QuoteControls, { QuoteControl } from './quote-controls'
import Quotes, { QuoteSlide, type QuoteProps } from './quotes'
import Logos, { LogoItem } from './logos'
import type { ImageProps } from 'next/image'
import capitalize from '@hashicorp/platform-util/text/capitalize'
import { Products } from '@hashicorp/platform-product-meta'
import s from './style.module.css'

export interface TestimonialsProps {
	quotes: Array<QuoteProps>
	logos: Array<ImageProps>
	product: Products
}

export default function Testimonials({
	quotes,
	logos,
	product,
}: TestimonialsProps) {
	const { activeIndex, setActiveIndex, moveForward } = useRovingIndex({
		maxIndex: quotes.length - 1,
		type: 'wrap',
	})
	const [hovering, setHovering] = useState(false)
	useInterval(() => moveForward(), hovering ? null : 5000)

	return (
		<div className={s.root}>
			<div className={s.quotesContainer}>
				<VisuallyHidden asChild id={`testimonials-quotes-slider-${product}`}>
					<h3>{`Testimonials for ${capitalize(product)}`}</h3>
				</VisuallyHidden>
				<Quotes
					onMouseOver={() => setHovering(true)}
					onMouseOut={() => setHovering(false)}
				>
					{quotes.map((quote, idx) => (
						<QuoteSlide
							key={quote.name}
							index={idx}
							activeIndex={activeIndex}
							{...quote}
						/>
					))}
				</Quotes>
				<QuoteControls>
					{quotes.map(({ name, jobTitle }, idx) => (
						<QuoteControl
							key={name}
							onClick={() => {
								setActiveIndex(idx)
							}}
							isActive={activeIndex === idx}
							name={name}
							jobTitle={jobTitle}
						/>
					))}
				</QuoteControls>
				{/* Allow assistive tech to announce changes in the slider content: https://www.w3.org/WAI/tutorials/carousels/functionality/ */}
				<VisuallyHidden aria-live="polite" aria-atomic="true">
					{`Testimonial ${activeIndex + 1} of ${quotes.length}`}
				</VisuallyHidden>
			</div>
			<div className={s.logosContainer}>
				<Logos>
					{logos.map((logo) => {
						return <LogoItem key={logo.alt} logo={logo} />
					})}
				</Logos>
			</div>
		</div>
	)
}
