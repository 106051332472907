import * as React from 'react'
import { animate, useInView } from 'framer-motion'

interface useCounterProps {
	from?: number
	to: number
	duration?: number
}

function useCounter({ from = 0, to, duration = 1 }: useCounterProps) {
	const ref = React.useRef(null)
	const isInView = useInView(ref, {
		once: true,
		amount: 'some',
	})
	const [value, setValue] = React.useState(from)

	React.useEffect(() => {
		if (!isInView) {
			return
		}
		const controls = animate(from, to, {
			duration,
			onUpdate(value) {
				setValue(value)
			},
		})

		return () => controls.stop()
	}, [isInView, from, to, duration])

	return {
		ref,
		count: value.toFixed(0),
	}
}

export default useCounter
