'use client'

/* eslint-disable @next/next/no-img-element */
import Image, { type ImageProps } from 'next/legacy/image'
import { useRef } from 'react'
import { m, useScroll, useTransform } from 'framer-motion'
import { useMediumUp } from '@hashicorp/react-mds/src/utils/hooks/use-screen-size'
import Text from 'components/text'
import s from './style.module.css'

export interface ImaxHeroProps {
	image: ImageProps
	logo: {
		src: string
		alt: string
	}
	heading: string
}

const AnimatedText = m(Text)

export default function ImaxHero({ heading, image, logo }: ImaxHeroProps) {
	const rootRef = useRef()
	const { scrollYProgress } = useScroll({
		target: rootRef,
		offset: ['start', 'end'],
	})
	const mediumUp = useMediumUp()
	const logoScale = useTransform(
		scrollYProgress,
		[0, 0.25],
		[mediumUp ? 1.5 : 1, 1]
	)
	const logoOpacity = useTransform(
		scrollYProgress,
		[0, 0.25],
		[mediumUp ? 0 : 1, 1]
	)
	const imageScale = useTransform(
		scrollYProgress,
		[0.25, 0.75],
		[1, mediumUp ? 0.85 : 1]
	)
	const contentOpacity = useTransform(
		scrollYProgress,
		[0.5, 1],
		[mediumUp ? 0 : 1, 1]
	)

	return (
		<section ref={rootRef} className={s.root}>
			<div className={s.container}>
				<div className={s.media}>
					<m.div
						style={{
							display: 'flex',
							scale: imageScale,
						}}
					>
						<Image
							layout="intrinsic"
							quality={75}
							{...image}
							alt={image.alt}
							className={s.image}
						/>
					</m.div>
					<m.img
						{...logo}
						className={s.logo}
						style={{
							scale: logoScale,
							opacity: logoOpacity,
						}}
						initial={{
							opacity: 0,
						}}
					/>
				</div>
				<m.div
					className={s.content}
					style={{ opacity: contentOpacity }}
					initial={{
						opacity: 0,
					}}
				>
					<AnimatedText variant="label">Customer Success Story</AnimatedText>
					<m.h2 className={s.heading}>
						<span dangerouslySetInnerHTML={{ __html: heading }} />
					</m.h2>
				</m.div>
			</div>
		</section>
	)
}
