/* eslint-disable no-nested-ternary */
import React from 'react'
import classNames from 'classnames'
import s from './style.module.css'

type Element = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'

type Spacing =
	| '01'
	| '02'
	| '03'
	| '04'
	| '05'
	| '06'
	| '07'
	| '08'
	| '09'
	| '10'
	| '11'
	| '12'
	| '13'
	| '14'
	| '15'

type Breakpoints = 'sm' | 'md' | 'lg'

type Margin =
	| Spacing
	| {
			[Breakpoint in Breakpoints]?: Spacing
	  }

type Variant =
	| 'display-1'
	| 'display-2'
	| 'display-3'
	| 'display-4'
	| 'display-5'
	| 'display-6'
	| 'body-large'
	| 'body'
	| 'body-strong'
	| 'body-x-strong'
	| 'body-italic'
	| 'body-small'
	| 'body-small-strong'
	| 'body-small-x-strong'
	| 'body-small-italic'
	| 'long-body'
	| 'long-body-strong'
	| 'long-body-italic'
	| 'buttons-and-standalone-links'
	| 'label'
	| 'label-small'
	| 'label-small-strong'
	| 'tag-label'

type FontWeight = 'light' | 'book' | 'reg' | 'medium' | 'semi-bold' | 'bold'

type Color =
	| 'neutral-0'
	| 'neutral-25'
	| 'neutral-50'
	| 'neutral-75'
	| 'neutral-100'
	| 'neutral-200'
	| 'neutral-300'
	| 'neutral-400'
	| 'neutral-500'
	| 'neutral-600'
	| 'neutral-700'
	| 'neutral-800'
	| 'neutral-825'
	| 'neutral-850'
	| 'neutral-875'
	| 'neutral-900'
	| 'neutral-alpha-8'
	| 'neutral-alpha-16'
	| 'neutral-alpha-24'
	| 'neutral-alpha-32'
	| 'neutral-alpha-40'
	| 'neutral-alpha-48'

interface TextProps {
	/**
	 * The rendered HTML element. There are instances where
	 * an element visually maps to .g-type-display-2 but semantically
	 * should be a h3. Ensure your heading levels coincide with their
	 * nesting level.
	 */
	as?: Element
	variant?: Variant
	color?: Color
	fontWeight?: FontWeight
	marginTop?: Margin
	marginRight?: Margin
	marginBottom?: Margin
	marginLeft?: Margin
	marginY?: Margin
	marginX?: Margin
	className?: string
	id?: string
	children: React.ReactNode
}

const Text = React.forwardRef<Element, TextProps>((props, ref) => {
	const {
		as,
		variant = 'body',
		color,
		fontWeight,
		marginTop,
		marginRight,
		marginBottom,
		marginLeft,
		marginY,
		marginX,
		className,
		children,
		id,
	} = props
	const headingElement = variant.includes('display')
		? (`h${variant.split('-')[1]}` as React.ElementType)
		: null
	const Component: React.ElementType = as || headingElement || 'p'
	const style = {
		'--text-color': color && `var(--wpl-${color})`,
		'--font-weight': fontWeight && `var(--font-weight-${fontWeight})`,
		// Margin Top
		'--margin-top-sm':
			typeof marginTop === 'string'
				? `var(--wpl-spacing-${marginTop})`
				: marginTop?.sm
				? `var(--wpl-spacing-${marginTop.sm})`
				: undefined,
		'--margin-top-md':
			typeof marginTop !== 'string' &&
			marginTop?.md &&
			`var(--wpl-spacing-${marginTop.md})`,
		'--margin-top-lg':
			typeof marginTop !== 'string' &&
			marginTop?.lg &&
			`var(--wpl-spacing-${marginTop.lg})`,
		// Margin Right
		'--margin-right-sm':
			typeof marginRight === 'string'
				? `var(--wpl-spacing-${marginRight})`
				: marginRight?.sm
				? `var(--wpl-spacing-${marginRight.sm})`
				: undefined,
		'--margin-right-md':
			typeof marginRight !== 'string' &&
			marginRight?.md &&
			`var(--wpl-spacing-${marginRight.md})`,
		'--margin-right-lg':
			typeof marginRight !== 'string' &&
			marginRight?.lg &&
			`var(--wpl-spacing-${marginRight.lg})`,
		// Margin Bottom
		'--margin-bottom-sm':
			typeof marginBottom === 'string'
				? `var(--wpl-spacing-${marginBottom})`
				: marginBottom?.sm
				? `var(--wpl-spacing-${marginBottom.sm})`
				: undefined,
		'--margin-bottom-md':
			typeof marginBottom !== 'string' &&
			marginBottom?.md &&
			`var(--wpl-spacing-${marginBottom.md})`,
		'--margin-bottom-lg':
			typeof marginBottom !== 'string' &&
			marginBottom?.lg &&
			`var(--wpl-spacing-${marginBottom.lg})`,
		// Margin Left
		'--margin-left-sm':
			typeof marginLeft === 'string'
				? `var(--wpl-spacing-${marginLeft})`
				: marginLeft?.sm
				? `var(--wpl-spacing-${marginLeft.sm})`
				: undefined,
		'--margin-left-md':
			typeof marginLeft !== 'string' &&
			marginLeft?.md &&
			`var(--wpl-spacing-${marginLeft.md})`,
		'--margin-left-lg':
			typeof marginLeft !== 'string' &&
			marginLeft?.lg &&
			`var(--wpl-spacing-${marginLeft.lg})`,
		// Margin Y: Top/Bottom
		'--margin-y-sm':
			typeof marginY === 'string'
				? `var(--wpl-spacing-${marginY})`
				: marginY?.sm
				? `var(--wpl-spacing-${marginY.sm})`
				: undefined,
		'--margin-y-md':
			typeof marginY !== 'string' &&
			marginY?.md &&
			`var(--wpl-spacing-${marginY.md})`,
		'--margin-y-lg':
			typeof marginY !== 'string' &&
			marginY?.lg &&
			`var(--wpl-spacing-${marginY.lg})`,
		// Margin X: Left/Right
		'--margin-x-sm':
			typeof marginX === 'string'
				? `var(--wpl-spacing-${marginX})`
				: marginX?.sm
				? `var(--wpl-spacing-${marginX.sm})`
				: undefined,
		'--margin-x-md':
			typeof marginX !== 'string' &&
			marginX?.md &&
			`var(--wpl-spacing-${marginX.md})`,
		'--margin-x-lg':
			typeof marginX !== 'string' &&
			marginX?.lg &&
			`var(--wpl-spacing-${marginX.lg})`,
	} as React.CSSProperties
	return (
		<Component
			ref={ref}
			id={id}
			className={classNames(
				s.text,
				className,
				`g-type-${variant}`,
				color && s.color,
				fontWeight && s.fontWeight,
				(typeof marginTop === 'string' || marginTop?.sm) && s.marginTopSm,
				typeof marginTop !== 'string' && marginTop?.md && s.marginTopMd,
				typeof marginTop !== 'string' && marginTop?.lg && s.marginTopLg,
				(typeof marginRight === 'string' || marginRight?.sm) && s.marginRightSm,
				typeof marginRight !== 'string' && marginRight?.md && s.marginRightMd,
				typeof marginRight !== 'string' && marginRight?.lg && s.marginRightLg,
				(typeof marginBottom === 'string' || marginBottom?.sm) &&
					s.marginBottomSm,
				typeof marginBottom !== 'string' &&
					marginBottom?.md &&
					s.marginBottomMd,
				typeof marginBottom !== 'string' &&
					marginBottom?.lg &&
					s.marginBottomLg,
				(typeof marginLeft === 'string' || marginLeft?.sm) && s.marginLeftSm,
				typeof marginLeft !== 'string' && marginLeft?.md && s.marginLeftMd,
				typeof marginLeft !== 'string' && marginLeft?.lg && s.marginLeftLg,
				(typeof marginY === 'string' || marginY?.sm) && s.marginYSm,
				typeof marginY !== 'string' && marginY?.md && s.marginYMd,
				typeof marginY !== 'string' && marginY?.lg && s.marginYLg,
				(typeof marginX === 'string' || marginX?.sm) && s.marginXSm,
				typeof marginX !== 'string' && marginX?.md && s.marginXMd,
				typeof marginX !== 'string' && marginX?.lg && s.marginXLg
			)}
			style={style}
		>
			{children}
		</Component>
	)
})

Text.displayName = 'Text'
export default Text
