import Image, { type ImageProps } from 'next/image'
import { ReactNode } from 'react'
import s from './style.module.css'

export default function Logos({ children }: { children: ReactNode }) {
	return <ul className={s.logos}>{children}</ul>
}

interface LogoItemProps {
	logo: ImageProps
}

export function LogoItem({ logo }: LogoItemProps) {
	return (
		<li className={s.logoItem}>
			<Image alt={logo.alt} {...logo} className={s.logo} />
		</li>
	)
}
