'use client'

import Image, { type ImageProps } from 'next/image'
import { m } from 'framer-motion'
import { type Products } from '@hashicorp/platform-product-meta'
import { IconCloud24 } from '@hashicorp/flight-icons/svg-react/cloud-24'
import { Button } from '@hashicorp/react-mds/src/components/button'
import StandaloneLink from 'components/hds-standalone-link'
import Text from 'components/text'
import { getOverviewPageData } from '../../server'
import ProductsSubnav from 'views/products/components/subnav'
import { useInView } from 'react-intersection-observer'
import s from './style.module.css'

export interface ProductOverviewHeroProps {
	heading: string
	description: string
	ctaLinks: Array<{
		url: string
		text: string
	}>
	accentImage: ImageProps
	product: Products
	subnav: Awaited<ReturnType<typeof getOverviewPageData>>['subnav']
}

export default function ProductOverviewHero({
	heading,
	description,
	ctaLinks,
	accentImage,
	product,
	subnav,
}: ProductOverviewHeroProps) {
	const { ref, inView } = useInView({ initialInView: true })

	return (
		<>
			<ProductsSubnav {...subnav} inView={inView} />
			<div className={s.root} ref={ref}>
				<div className={s.inner}>
					<div className={s.content}>
						<m.div
							className={s.content}
							initial={{ opacity: 0, y: '50px' }}
							animate={{ opacity: 1, y: 0 }}
							transition={{ ease: 'easeInOut', duration: 1 }}
						>
							<Text variant="display-1" color="neutral-0">
								{heading}
							</Text>
							<Text
								variant="body-large"
								color="neutral-400"
								marginTop={{ sm: '03', md: '05' }}
								marginBottom="06"
							>
								{description}
							</Text>
						</m.div>
						<CallToActions ctaLinks={ctaLinks} product={product} />
					</div>
				</div>
				<div className={s.imageWrapper}>
					<Image {...accentImage} alt="" priority />
				</div>
			</div>
		</>
	)
}

interface CallToActionsProps
	extends Pick<ProductOverviewHeroProps, 'ctaLinks'> {
	product: Products
}

function CallToActions({ ctaLinks, product }: CallToActionsProps) {
	const isNomad = product === 'nomad'
	return (
		<m.ul
			className={s.ctaLinks}
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			transition={{ ease: 'easeInOut', duration: 1, delay: 0.4 }}
		>
			{ctaLinks.map(({ url, text }, idx: number) => (
				<li className={s.cta} key={text}>
					{idx === 0 ? (
						<Button
							url={url}
							title={text}
							theme={{
								variant: 'primary',
								brand: product as Products,
							}}
							icon={
								isNomad ? null : (
									<IconCloud24
										style={{ display: 'block' }}
										width="100%"
										height="100%"
									/>
								)
							}
							iconPosition="leading"
						/>
					) : (
						<StandaloneLink text={text} color="secondary-inverted" href={url} />
					)}
				</li>
			))}
		</m.ul>
	)
}
